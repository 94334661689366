import revive_payload_client_Frd8gASYWa from "/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_6haAUckR5u from "/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_T5aD6w6QZh from "/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BKbTeWPbAR from "/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WyVg3e7r6I from "/node_modules/nuxt-permissions/dist/runtime/plugin.mjs";
import axios_QMFgzss1s4 from "/src/plugins/axios.ts";
import plugin_aMPjkA2BVh from "/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import ably_client_qxNSZBlBGP from "/src/plugins/ably.client.ts";
import apexcharts_client_Dd5Ebxj7XB from "/src/plugins/apexcharts.client.ts";
import formatCurrency_Ud4HNt2xAs from "/src/plugins/formatCurrency.ts";
import mitt_client_7uatExallr from "/src/plugins/mitt.client.ts";
import moment_lgOtqBZZj3 from "/src/plugins/moment.js";
import saveToPdf_Sfv5rF6R6U from "/src/plugins/saveToPdf.ts";
import saveToPdfTwo_9WKqMlGlq7 from "/src/plugins/saveToPdfTwo.ts";
import sentry_client_shVUlIjFLk from "/src/plugins/sentry.client.ts";
import toast_Zl2rZYKMaV from "/src/plugins/toast.js";
import v_tooltip_client_eCNro5Imd5 from "/src/plugins/v-tooltip.client.ts";
import vuetelinput_zFVxC8ItKm from "/src/plugins/vuetelinput.js";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  debug_6haAUckR5u,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  plugin_WyVg3e7r6I,
  axios_QMFgzss1s4,
  plugin_aMPjkA2BVh,
  ably_client_qxNSZBlBGP,
  apexcharts_client_Dd5Ebxj7XB,
  formatCurrency_Ud4HNt2xAs,
  mitt_client_7uatExallr,
  moment_lgOtqBZZj3,
  saveToPdf_Sfv5rF6R6U,
  saveToPdfTwo_9WKqMlGlq7,
  sentry_client_shVUlIjFLk,
  toast_Zl2rZYKMaV,
  v_tooltip_client_eCNro5Imd5,
  vuetelinput_zFVxC8ItKm
]